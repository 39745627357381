import React, { Component} from 'react';
import './App.css';


export default class App extends Component {

  state = {
    dataReady: true,
    data: [   
      {
          "id": "",
          "token": "",
          "asic_id": "",
          "rig_id": "",
          "user_id": "",
          "user_email": "",
          "dev_type": "",
          "conf_algo": "",
          "conf_wallet": "",
          "conf_pool1": "",
          "conf_pool2": "",
          "hosting_period": "",
          "last_api_call": ""
      }
    ],
    data_filtered: [],
    filters: {
        id: '',
        token: '',
        asic_id: '',
        rig_id: '',
        user_id: '',
        user_email: '',
        dev_type: '',
        conf_algo: '',
        conf_wallet: '',
        conf_pool1: '',
        hosting_period: '',
        last_api_call: '',
        isExpired: true,
        isInactive: true,
        isEmptyUser: true,
        isEmptyConf: true
    },
    select_dev_types: [],
    select_conf_algos: [],
    serverDate: '',
    error: {error: ''},
    session_id: null,
    loading: true,
    username: '',
    password: ''
  }

  
componentDidMount(){
    let filters = this.state.filters;
    let stored_filters = JSON.parse(localStorage.getItem('filters'));
    if (stored_filters) filters = stored_filters;
    let session_id = sessionStorage.getItem('session_id');
    this.setState({
        session_id,
        filters 
    }, this.fetchData);
}

fetchSessionId(username, password){
    this.setState({loading: true});
    
    fetch('https://hostingapi.mwdataco.com/admin/sys/sessionid',{
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({username: this.state.username, password: this.state.password})
    })
    .then(response => response.json())
    .then((data)=>{
        sessionStorage.setItem('session_id', data.session_id);
        this.setState({
            session_id: data.session_id,
            username: '',
            password: ''
        }, this.fetchData);
    });
    
}

resetSession(){
    sessionStorage.removeItem('session_id');
    this.setState({
        error: {error: 'bad api key'},        
    });
}

fetchServerDate(){
    fetch('https://hostingapi.mwdataco.com/sys/now')
    .then(response => response.json())
    .then((serverDate)=>{
        if (!serverDate.error){
            serverDate = serverDate.replace(/^"|"$/g, '');
            this.setState({
                serverDate
            });
        }
    });
}

fetchData(){
    this.setState({loading: true});
    this.fetchServerDate();
    fetch('https://hostingapi.mwdataco.com/admin/sys/getformattedlist/'+this.state.session_id)
    .then(response => response.json())
    .then((data)=>{
        if (!data.error){
            this.setState({
                data,
                error: null,
                loading: false
            }, this.filterData);
        }else{
            this.setState({
                error: data,
                loading: false
            });
        }
    });
}

filterData (){
    const filters = this.state.filters;
    let data_filtered = this.state.data.filter((fpga)=>{
        var filter = false;
        
        if (( filters.id !== '' ) && ( (fpga.id+'').indexOf(filters.id) === -1 )) filter = true;
        if (( filters.token !== '' ) && ( (fpga.token+'').indexOf(filters.token) === -1 )) filter = true;
        if (( filters.asic_id !== '' ) && ( (fpga.asic_id+'').indexOf(filters.asic_id) === -1 )) filter = true;
        if (( filters.rig_id !== '' ) && ( (fpga.rig_id+'').indexOf(filters.rig_id) === -1 )) filter = true;
        if (( filters.user_email !== '' ) && ( (fpga.user_email+'').indexOf(filters.user_email) === -1 )) filter = true;
        if (( filters.dev_type !== '' ) && ( fpga.dev_type !== filters.dev_type )) filter = true;
        if (( filters.conf_algo !== '' ) && ( (fpga.conf_algo+'').indexOf(filters.conf_algo) === -1 )) filter = true;
        if (( filters.conf_wallet !== '' ) && ( (fpga.conf_wallet+'').indexOf(filters.conf_wallet) === -1 )) filter = true;
        if (( filters.conf_pool1 !== '' ) && ( (fpga.conf_pool1+'').indexOf(filters.conf_pool1) === -1 )) filter = true;
        if (( filters.hosting_period !== '' ) && ( (this.formatDate(fpga.hosting_period)).indexOf(filters.hosting_period) === -1 )) filter = true;
        if (( filters.last_api_call !== '' ) && ( (this.formatDate(fpga.last_api_call)).indexOf(filters.last_api_call) === -1 )) filter = true;


        if (( !filters.isEmptyUser ) && ( fpga.user_id === 22 )) filter = true;
        if (( !filters.isEmptyConf ) && ( !fpga.conf_wallet )) filter = true;
        if (( !filters.isExpired ) && ( this.isExpired(fpga.hosting_period) )) filter = true;
        if (( !filters.isInactive ) && ( this.isInactive(fpga.last_api_call) )) filter = true;

        return filter === false
    })
    
    this.setState({
        data_filtered
    })
}

changeUsername(e){
    this.setState({ username: e.target.value });
}  

changePassword(e){
    this.setState({ password: e.target.value });
}  

filterChange(filterName, e){
    var filters = this.state.filters;
    filters[filterName] = e.target.value;
    this.setState({filters}, ()=>{
        this.filterData();
        localStorage.setItem('filters', JSON.stringify(filters));
    });
}  

filterCheckboxChange(checkboxName, e){
    var filters = this.state.filters;
    filters[checkboxName] = e.target.checked;
    this.setState({filters}, ()=>{
        this.filterData();
        localStorage.setItem('filters', JSON.stringify(filters));
    });
}  


filterClear(){
    var filters = {
        id: '',
        token: '',
        asic_id: '',
        rig_id: '',
        user_id: '',
        user_email: '',
        dev_type: '',
        conf_algo: '',
        conf_wallet: '',
        conf_pool1: '',
        hosting_period: '',
        last_api_call: '',
        isExpired: true,
        isInactive: true,
        isEmptyUser: true,
        isEmptyConf: true
    };
    this.setState({filters}, ()=>{
        this.filterData();
        localStorage.setItem('filters', JSON.stringify(filters));
    });
}

formatDate(jsonDate){
    let strDate = '';
    if (jsonDate) {
        var d = new Date(jsonDate);
        strDate = d.getUTCFullYear() + '-' + ("0"+(d.getUTCMonth()+1)).slice(-2) + '-' + ("0" + d.getUTCDate()).slice(-2) + ' ' + ("0" + d.getUTCHours()).slice(-2) + ':' + ("0" + d.getUTCMinutes()).slice(-2);
    }
    return strDate;
}

isExpired(date){
    let c = new Date(this.state.serverDate);
    let d = new Date(date);
    return ((c - d) > 0) ? true : false;
}

isInactive(date){
    let c = new Date(this.state.serverDate);
    let d = new Date(date);
    return ((c - d) > 600000) ? true : false;
}


render() {
    
    return (
    <div className="App">
        {(this.state.loading)
        ?<div className="loading_wrap">
            <img src="images/circles-menu-1.gif" alt="loading" />
        </div>
        :<div>
        {( this.state.error )
            ? <div className="loginForm_wrap">
                {( this.state.error.error === 'bad api key' )
                ? <div className="loginForm">
                    <form onSubmit={this.fetchSessionId.bind(this)}>
                        <input type="text" name="username" value={this.state.username} onChange={this.changeUsername.bind(this)} placeholder="username"/>
                        <input type="password" name="password" value={this.state.password} onChange={this.changePassword.bind(this)} placeholder="password"/>
                        <input type="submit" value="Login" />
                    </form>
                </div>
                :<div>{this.state.error.error}</div>
                }
                
            </div>
            :<div>
                <div className="info">
                    <span>Server Time: {this.formatDate(this.state.serverDate)}</span>
                    <span>Total Records: {this.state.data.length}</span>
                    <span>Selected Records: {this.state.data_filtered.length}</span>
                    <button title="Logout" className="clearButton logoutButton" onClick={this.resetSession.bind(this)}>
                        <img src="images/close-window.png" alt="logout" height="24"/>
                    </button>
                </div>
                <table>
                    <thead>
                    <tr>
                            <th colSpan="5">Device</th>
                            <th colSpan="2">User</th>
                            <th colSpan="4">Config</th>
                        </tr>
                        <tr>
                            <th>#</th>
                            <th>Rig Id</th>
                            <th>Token</th>
                            <th>Asic Id</th>
                            <th>Type</th>
                            <th>User Email</th>
                            <th>Hosting Period</th>
                            <th>Last Api Call</th>
                            <th>Algo</th>
                            <th>Pool</th>
                            <th>Wallet</th>

                        </tr>
                        <tr className="inputs">
                            <th><button title="Clear filters" className="clearButton" onClick={this.filterClear.bind(this)}>
                            <img src="images/delete-sign.png" alt="clearfilters" height="20"/>
                            </button></th>
                            <th><input type="text" value={this.state.filters.rig_id} onChange={this.filterChange.bind(this, 'rig_id')} /></th>
                            <th><input type="text" value={this.state.filters.token} onChange={this.filterChange.bind(this, 'token')} /></th>
                            <th><input type="text" value={this.state.filters.asic_id} onChange={this.filterChange.bind(this, 'asic_id')} /></th>
                            <th>
                                <select value={this.state.filters.dev_type} onChange={this.filterChange.bind(this, 'dev_type')} >
                                    <option value=""> </option>
                                    <option value="BCU1525">BCU1525</option>
                                    <option value="BCU1525_v2">BCU1525_v2</option>
                                    <option value="CVP13">CVP13</option>
                                    <option value="FK33">FK33</option>
                                </select>
                            </th>
                            <th><input type="text" value={this.state.filters.user_email} onChange={this.filterChange.bind(this, 'user_email')} /><br/>
                                <span className="checkbox"><input type="checkbox" name="check_no_user" checked={this.state.filters.isEmptyUser}
                                onChange={this.filterCheckboxChange.bind(this, 'isEmptyUser')} /> No User</span>
                            </th>
                            <th>
                                <input type="text" value={this.state.filters.hosting_period} onChange={this.filterChange.bind(this, 'hosting_period')} /><br/>
                                <span className="checkbox"><input type="checkbox" name="check_expired" checked={this.state.filters.isExpired} 
                                onChange={this.filterCheckboxChange.bind(this, 'isExpired')} /> Expired</span>
                            </th>
                            <th><input type="text" value={this.state.filters.last_api_call} onChange={this.filterChange.bind(this, 'last_api_call')} /><br/>
                                <span className="checkbox"><input type="checkbox" name="check_inactive" checked={this.state.filters.isInactive} 
                                onChange={this.filterCheckboxChange.bind(this, 'isInactive')}/> Inactive</span>
                            </th>
                            <th><input type="text" value={this.state.filters.conf_algo} onChange={this.filterChange.bind(this, 'conf_algo')} /></th>
                            <th><input type="text" value={this.state.filters.conf_pool1} onChange={this.filterChange.bind(this, 'conf_pool1')} /></th>
                            <th><input type="text" value={this.state.filters.conf_wallet} onChange={this.filterChange.bind(this, 'conf_wallet')} /><br/>
                                <span className="checkbox"><input type="checkbox" name="check_empty_conf" checked={this.state.filters.isEmptyConf} 
                                onChange={this.filterCheckboxChange.bind(this, 'isEmptyConf')} /> Empty Config</span>
                            </th>

                        </tr>
                    </thead>
                    <tbody>                
                        {this.state.data_filtered.map((fpga, i) =>
                        <tr key={i+1}>
                            <td style={{color: 'gray'}}>{i+1}</td>
                            <td>{fpga.rig_id}</td>
                            <td>{fpga.token}</td>
                            <td>{fpga.asic_id}</td>
                            <td>{fpga.dev_type}</td>
                            {(fpga.user_id === 22) 
                                ?<td style={{color: 'lightgray'}}>{fpga.user_email} (no user)</td>
                                :<td style={{color: 'black'}}>{fpga.user_email}</td>
                            }
                            {this.isExpired(fpga.hosting_period) 
                                ?<td style={{color: 'gray'}}>{this.formatDate(fpga.hosting_period)}</td>
                                :<td style={{color: 'green'}}>{this.formatDate(fpga.hosting_period)}</td>
                            }
                            {this.isInactive(fpga.last_api_call) 
                                ?<td style={{color: 'red'}}>{this.formatDate(fpga.last_api_call)}</td>
                                :<td style={{color: 'green'}}>{this.formatDate(fpga.last_api_call)}</td>
                            }
                            <td>{fpga.conf_algo}</td>
                            <td>{fpga.conf_pool1}</td>
                            <td>{fpga.conf_wallet}</td>

                        </tr>
                        )}
                    </tbody>
                </table>
            </div>

        }
        </div>
        }
    </div>
    )
  }
}